import React from 'react';
import PropTypes from 'prop-types';
import CSSModules from 'react-css-modules';
import HTMLParser from 'html-react-parser';
import Axios from 'axios';

import { Carousel } from 'react-responsive-carousel';
import instagramLogo from '../../../../../api/instagrem.svg';

import styles from './styles.module.css';
import ButtonEditor from '../../ButtonEditor';
import '../../../../carousel.css';

const axios = Axios.create({
  withCredentials: false,
});

class InstagramLayout extends React.PureComponent {

  constructor(props) {

    super(props);

    const align = props.section.data[props.section.data.length - 1].align !==
      undefined ? props.section.data[props.section.data.length - 1].align : 'Left';

    this.state = {
      section: props.section,
      align,
      layout: props.layout,
    };

    this.handleImageError = this.handleImageError.bind(this);

  }

  componentWillMount() {

    this.componentIsMounted = true;
    this.getInstagramData(this.props.section.data[1].data.access_token);

  }

  componentWillUnmount() {

    this.componentIsMounted = false;

  }

  getInstagramData(accessToken) {

    const imageCount = this.props.section.data[1].data.image_count || 12;
    const url = `https://api.instagram.com/v1/users/self/media/recent/?access_token=${accessToken}&count=${imageCount}`;

    if ((this.state.instagramData && this.state.instagramData.length > 0)
      || !accessToken || accessToken.length < 8) {

      if (!this.componentIsMounted) {

        return;

      }

      this.setState({
        instagramData: [],
      });

      return;

    }

    axios.get(url)
      .then((response) => {

        this.setState({
          instagramData: response.data.data,
        });

      })
      .catch((err) => {

        console.log(err);
        this.setState({
          instagramData: [],
        });

      });

  }

  handleImageError(index) {

    const newInstaData = this.state.instagramData;

    if (!newInstaData) {

      return;

    }

    newInstaData[index].hidden = true;

    this.setState({
      instagramData: newInstaData,
    });

  }

  render() {

    const colGridWidth = 12 / this.state.section.data[1].data.column_amount;
    const bootstrapGridStyle = `col-${colGridWidth} col-sm-${colGridWidth} col-md-${colGridWidth} col-lg-${colGridWidth}`;

    const headerAlignStyle = `instagramHeader${this.state.align}`;

    const themeHeadingStyle = {

      fontFamily: this.props.themeData.typography.heading.name,
      fontWeight: this.props.themeData.typography.heading.weight,
      lineHeight: this.props.themeData.typography.heading.lineHeight,
      letterSpacing: this.props.themeData.typography.heading.letterSpacing,

    };

    const color3 = {
      color: this.props.themeData.colors[3],
    };

    const alignStyle = `align${this.state.align}`;


    const inlineStyle = { ...themeHeadingStyle, ...color3 };
    const full = this.props.full ? 'Full' : '';

    // Carousel stuff


    const arrows = false;
    const cards = [];


    if (this.state.instagramData && this.state.instagramData.length > 0) {

      this.state.instagramData.map((item, index) => {

        let itemText = '';

        if (item.images &&
          item.images.standard_resolution &&
          item.images.standard_resolution.url &&
          !item.hidden) {

          if (item.caption && item.caption.text) {

            itemText = item.caption.text;

          }


          const card = (
            <div key={`instagramImage_${item.images.standard_resolution.url}_${index}`} className={styles.carouselWrapper}>
              <div className={styles.carouselImageWrapper}>
                <img
                  src={item.images.standard_resolution.url}
                  alt={itemText}
                  className={styles.carouselImg}
                />
              </div>
            </div>
          );

          cards.push(card);


        }

        return null;

      });

    }


    return (

    <div
      className={`${full ? '' : 'container'} ${this.props.full ? styles.instagramContainerFull : styles.instagramContainer}`}
    >
      {
        this.state.section.data[0].active && (
          <div
            key={`${this.state.section._id}_layout_0`}
            className={
              `${styles[headerAlignStyle]} Title2 textItem_${this.state.section._id}_0`
            }
            role="button"
            tabIndex="0"
            style={inlineStyle}
          >
            {
              <div
                key={`${this.state.section.data[0].data.src}_0`}
              >
                {
                  HTMLParser(`<h2>${this.state.section.data[0].data}</h2>`)
                }
              </div>
            }
          </div>

        )
      }
      {
        <div className={`row ${styles.instagramRowWrapper} ${styles[alignStyle]}`}>
          {
            [
              this.state.section.data.map((item, index) => {

                if (item.active) {

                  if (item.type.startsWith('BUTTONS')) {

                    const link = `https://instagram.com/${this.state.section.data[1].data.username}`;
                    const newData = item.data;
                    newData.link = link;

                    return (
                      <div
                        key={`${this.state.section._id}_layout_${index}`}
                        className={styles.instagramBtnWrapper}
                      >
                        <ButtonEditor
                          themeData={this.props.themeData}
                          button="Secondary"
                          data={newData}
                          domain={this.props.domain}
                          page={this.props.page}
                          index={index}
                          section={this.state.section}
                          componentIndex={null}
                          elementIndex={index}
                          image={instagramLogo}
                        />
                      </div>
                    );

                  }

                }

                return null;

              }),
              (!this.props.mobileCarousel || !this.props.mobileView) ?
                (this.state.instagramData &&
                  this.state.instagramData.map((instaItem, instaIndex) => {

                    if (instaItem.images &&
                      instaItem.images.standard_resolution &&
                      instaItem.images.standard_resolution.url &&
                      !instaItem.hidden) {

                      return (
                        <div
                          id={`${this.state.section._id}_Image_1_instagramSection_${instaIndex + 1}`}
                          role="button"
                          onBlur={this.handleComponentBlur}
                          key={`${this.state.section._id}_section_1_instagramFeedImage_${instaIndex + 1}`}
                          className={`${bootstrapGridStyle} ${this.state.layout === 'gutters' ?
                            styles.instagramGuttersImage
                            :
                            styles.instagramNoGuttersImage}`}
                          onKeyPress={this.handleImageClick}
                          onClick={this.handleImageClick}
                          onError={() => {

                            this.handleImageError(instaIndex);

                          }}
                        >
                          <div className={styles.instagramImageWrapper}>
                            <img
                              key={`section_1_instaImage_${instaIndex + 1}`}
                              alt=""
                              data-mediatype="IMAGE"
                              data-disableicon
                              className={`ImgStopAnchor ${styles.instagramImg}`}
                              src={instaItem.images.standard_resolution.url}
                            />
                          </div>
                        </div>
                      );

                    }

                    return null;

                  })) :
                (
                  (cards && cards.length > 0) && (
                    <div
                      key={`${this.state.page}_page_${this.state.section._id}_section_1_carouselContainerDiv`}
                      className={`this.props.full ? 'container' : '' ${this.props.full ? styles.carouselContainerFull : styles.carouselContainer}`}
                    >
                      <div
                        key={`${this.state.page}_page_${this.state.section._id}_section_1_carouselDiv`}
                        className={`row ${this.props.full ? styles.noMargin : ''}`}
                      >
                        <div className={`col-12 ${styles.carouselCol}`} key={`${this.state.section._id}_section_1_carouselDiv2`} >
                          <Carousel
                            showThumbs={false}
                            showArrows={arrows}
                            showIndicators
                            autoPlay
                            infiniteLoop
                            interval={5}
                            axis="horizontal"
                          >
                            { cards }
                          </Carousel>
                        </div>
                      </div>
                    </div>

                  )
                ),
            ]
          }
        </div>
      }
      </div>
    );

  }

}

InstagramLayout.propTypes = {
  mobileView: PropTypes.bool,
  mobileCarousel: PropTypes.bool,
  page: PropTypes.string,
  align: PropTypes.string,
  section: PropTypes.shape({
    data: PropTypes.array,
  }),
  active: PropTypes.string,
  onTextClick: PropTypes.func,
  onButtonClick: PropTypes.func,
  onBlur: PropTypes.func,
  layout: PropTypes.string,
  full: PropTypes.string,
  theme: PropTypes.string,
  domain: PropTypes.string,
  themeData: PropTypes.shape({
    colors: PropTypes.arrayOf(PropTypes.string),
    typography: PropTypes.shape({
      default: PropTypes.shape({
        name: PropTypes.string,
        weight: PropTypes.string,
        lineHeight: PropTypes.string,
        letterSpacing: PropTypes.string,
      }),
      heading: PropTypes.shape({
        name: PropTypes.string,
        weight: PropTypes.string,
        lineHeight: PropTypes.string,
        letterSpacing: PropTypes.string,
      }),
      navigation: PropTypes.shape({
        name: PropTypes.string,
        weight: PropTypes.string,
        lineHeight: PropTypes.string,
        letterSpacing: PropTypes.string,
      }),
    }),
  }),
  imageCount: PropTypes.number,
};


export default CSSModules(
  InstagramLayout, styles,
  { allowMultiple: true },
);
